<template>
  <div>
    <b-card
      title="Pembayaran Supir Belum Dibayar"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Jumlah -->
        <template #cell(jumlah_pembayaran_supir)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Jumlah Kas -->
        <template #cell(jumlah_kas)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Tanggal Berangkat - Tanggal Balik -->
        <template #cell(tanggal_berangkat)="data">
          <span>{{ data.value }} - {{ data.item.tanggal_balik }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            variant="light-danger"
          >
            {{ data.value === 'belum' ? 'Belum Dibayar' : 'Belum Dibayar' }}
          </b-badge>
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`PembayaranSupir-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="bayarPembayaranSupir(data.item, true)"
              >
                Bayarkan
              </b-button>
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`PembayaranSupir-row-${data.item.id}-edit-icon`"
              />

              <!-- <b-button
                :id="`PembayaranSupir-row-${data.item.id}-hapus-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Hapus
              </b-button> -->
              <b-tooltip
                title="Hapus"
                :target="`PembayaranSupir-row-${data.item.id}-hapus-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPembayaranSupir"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-card
      title="Riwayat Pembayaran Supir"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQueryLunas"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rowsLunas"
        responsive
        :fields="columnsLunas"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPageLunas"
        :filter="searchQueryLunas"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Jumlah -->
        <template #cell(jumlah_pembayaran_supir)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Jumlah Kas -->
        <template #cell(jumlah_kas)="data">
          <span class="text-warning">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Tanggal Berangkat - Tanggal Balik -->
        <template #cell(tanggal_berangkat)="data">
          <span>{{ data.value }} - {{ data.item.tanggal_balik }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            variant="light-success"
          >
            {{ data.value === 'belum' ? 'Belum Dibayar' : 'Sudah Dibayar' }}
          </b-badge>
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`PembayaranSupir-row-${data.item.id}-preview-icon`"
                size="sm"
                variant="info"
                @click="previewCetak(data.item)"
              >
                Cetak
              </b-button>
              <b-tooltip
                title="Cetak"
                class="cursor-pointer"
                :target="`PembayaranSupir-row-${data.item.id}-preview-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPageLunas"
              :total-rows="totalPembayaranSupirLunas"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <AddPembayaranSupirContent
      v-model="addPembayaranSupirSidebar"
      :pembayaran-supir="dataEditPembayaranSupir"
      @pembayaran-supir-sidebar-change="bayarPembayaranSupir"
      @submit-data-pembayaran-supir="submitData"
    />
    <b-modal
      id="modal-cetal"
      v-model="modalCetak"
      ok-only
      ok-title="Close"
      centered
      size="xl"
      title="Pembayaran Supir Invoice"
    >
      <preview-cetak
        :pembayaran="dataPembayaranPreview"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable,
  BPagination, BTooltip, BButtonGroup, BBadge,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import AddPembayaranSupirContent from './Add.vue'
import previewCetak from './Preview.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BButtonGroup,
    BBadge,

    AddPembayaranSupirContent,
    previewCetak,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Supir',
          key: 'supir.nama',
        },
        {
          label: 'Tujuan',
          key: 'tujuan',
        },
        {
          label: 'Jumlah',
          key: 'jumlah_pembayaran_supir',
        },
        {
          label: 'Jumlah Kas',
          key: 'jumlah_kas',
        },
        {
          label: 'Tanggal',
          key: 'tanggal_berangkat',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Action',
          key: 'action',
        },
      ],
      columnsLunas: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Supir',
          key: 'supir.nama',
        },
        {
          label: 'Tujuan',
          key: 'tujuan',
        },
        {
          label: 'Jumlah',
          key: 'jumlah_pembayaran_supir',
        },
        {
          label: 'Jumlah Kas',
          key: 'jumlah_kas',
        },
        {
          label: 'Tanggal',
          key: 'tanggal_berangkat',
        },
        {
          label: 'Status',
          key: 'status',
        },
        {
          label: 'Action',
          key: 'action',
        },
      ],
      perPage: 5,
      totalPembayaranSupir: 0,
      totalPembayaranSupirLunas: 0,
      currentPage: 1,
      currentPageLunas: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      searchQueryLunas: '',
      sortBy: 'tanggal_berangkat',
      isSortDirAsc: true,
      statusFilter: null,
      rowsData: [],
      rowsDataLunas: [],
      searchTerm: '',
      statusOptions: [
        'Aktif',
        'Tidak Aktif',
      ],
      addPembayaranSupirSidebar: false,
      dataEditPembayaranSupir: {
        id: '1',
        supir: null,
        keterangan: null,
        harga: null,
        tanggal: null,
      },
      modalCetak: false,
      dataPembayaranPreview: {},
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      return this.rowsData
    },
    rowsLunas() {
      return this.rowsDataLunas
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      // eslint-disable-next-line no-return-assign
      this.$http.get('/booking/pembayaran-supir/sudah-dibayar').then(response => {
        this.rowsDataLunas = response.data.data
        this.totalPembayaranSupirLunas = response.data.data.length
      })
      this.$http.get('/booking/pembayaran-supir/belum-dibayar').then(response => {
        this.rowsData = response.data.data
        this.totalPembayaranSupir = response.data.data.length
      })
    },
    bayarPembayaranSupir(data, value) {
      this.dataEditPembayaranSupir = data
      this.addPembayaranSupirSidebar = value
    },
    submitData(data) {
      if (data.id === null) {
        const formData = new FormData()
        formData.append('nama', data.nama)
        formData.append('telp', data.no_telp)
        formData.append('status', data.status)

        this.$http.post('/PembayaranSupir-add', formData).then(response => {
          this.getData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: response.data.message,
              variant: 'success',
            },
          })
        })
      } else {
        const formData = new FormData()
        formData.append('jumlah_pembayaran_supir', data.jumlah)

        this.$http.post(`/booking/pembayaran-supir/${data.id}`, formData).then(response => {
          this.getData()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'BellIcon',
              text: response.data.message,
              variant: 'success',
            },
          })
        })
      }
      this.addPembayaranSupirSidebar = false
    },
    removeData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.rowsData = this.rowsData.filter(item => item.id !== id)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    readableDate(data) {
      const options = {
        year: 'numeric', month: 'long', day: 'numeric',
      }
      return new Date(data).toLocaleDateString(undefined, options)
    },
    previewCetak(data) {
      this.modalCetak = true
      this.dataPembayaranPreview = data
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
